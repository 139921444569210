// vendors
import 'bootstrap/scss/bootstrap.scss';
import '@fortawesome/fontawesome-free/scss/fontawesome.scss';
import 'webpack-jquery-ui/css';
import 'toastr/toastr.scss';
import '../scss/jquery.gritter.scss';
import '../scss/ace.scss';
import '../scss/ace-fonts.scss';
import '../scss/ace-rtl.scss';
import '../scss/ace-skins.scss';
import '../scss/daterangepicker.scss';
import '../scss/datatable.scss';

// custom css
import '../scss/app.scss';
import '../scss/main.scss';

// vendors
import $ from 'jquery';
// create global $ and jQuery variables
//In a web context, using global and window are equivalent, except that window.jQuery won’t work when using autoProvidejQuery()
global.$ = global.jQuery = $;

import '@fortawesome/fontawesome-free/js/all';
import 'bootstrap' ;
// with an AMD loader for DataTables you don't need to execute a function
require("datatables.net");
import bootbox from  'bootbox';
global.bootbox = bootbox;

require('webpack-jquery-ui');
import toastr from 'toastr';
global.toastr = toastr;
